import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserDetails } from '../../redux/linkedUserSlice'
import { selectAccessToken, selectUserData } from '../../redux/userSlice'
import { fetchNotifications, selectNotifications, setUnreadCount } from '../../redux/notificationSlice'
import { markMessagesAsRead } from '../Chat/api'
import './../../assets/components_css/NotificationTray.css'

const NotificationTray = () => {
    const dispatch = useDispatch()
    const accessToken = useSelector(selectAccessToken)
    const user = useSelector(selectUserData)
    const accountManager = useSelector(state => state.userAccountManager.accountManager)
    const notifications = useSelector(selectNotifications)

    const [fetchedUsers, setFetchedUsers] = useState({});

    const handleMarkAllAsRead = async () => {
        try {
            const uniqueSenderIds = [...new Set(notifications.map(n => n.senderId))]
            await Promise.all(uniqueSenderIds.map(senderId => markMessagesAsRead(senderId, accessToken)))
            dispatch(fetchNotifications(accessToken))
            dispatch(setUnreadCount(0));
        } catch (error) {
            console.error('Error marking messages as read:', error)
        }
    }

    const fetchAndSetUserDetails = async (senderId) => {
        if (!fetchedUsers[senderId]) {
            try {
                const userDetails = await dispatch(fetchUserDetails(senderId)).unwrap()
                setFetchedUsers(prev => ({
                    ...prev,
                    [senderId]: userDetails
                }))
            } catch (error) {
                console.error('Failed to fetch user details:', error);
            }
        }
    }

    useEffect(() => {
        const fetchUserDetailsForNotifications = async () => {
            if (user?.role === 'account_manager') {
                const uniqueSenderIds = [...new Set(notifications.map(n => n.senderId))]
                for (const senderId of uniqueSenderIds) {
                    await fetchAndSetUserDetails(senderId)
                }
            }
        };
        fetchUserDetailsForNotifications()
    }, [notifications, user?.role])

    const getSenderName = (senderId) => {
        if (user?.role === 'user') {
            if (accountManager?.id === senderId) {
                return `${accountManager.first_name} ${accountManager.last_name}`;
            }
        } else if (user?.role === 'account_manager') {
            const senderDetails = fetchedUsers[senderId];
            if (senderDetails?.id === senderId) {
                return `${senderDetails.first_name} ${senderDetails.last_name}`;
            }
        }
        return 'Unknown';
    };

    // const trayRef = useRef();

    // const handleClickOutside = useCallback((event) => {
    //   if (trayRef.current && !trayRef.current.contains(event.target)) {
    //     handleTrayClose(false);
    //   }
    // }, [handleTrayClose]);
  
    // useEffect(() => {
    //   document.addEventListener('mousedown', handleClickOutside);
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, [handleClickOutside]);

    // console.log('handleTrayClose', handleTrayClose)

    return (
        <div className='notification-tray' onClick={(e) => e.stopPropagation()}>

            {notifications.length > 0 ? (
                <>
                    <button className="mark-all-as-read-button" onClick={handleMarkAllAsRead}>
                        Mark all as Read
                    </button>
                    <div>
                        <ul className='notification-list'>
                            {notifications.map((notification, index) => (
                                <li key={index} className='notification-item'>
                                    <p><strong>{getSenderName(notification.senderId)}</strong>: {notification.message}</p>
                                    <small>{new Date(notification.timestamp).toLocaleString()}</small>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            ) : (
                <p className='no-noti-show'>No notifications to show</p>
            )}
        </div>
    )
}

export default NotificationTray