import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const CodeCheckRoute = ({ children }) => {
    const location = useLocation();
    const hasCode = new URLSearchParams(location.search).has('code');

    if (!hasCode) {
        // Redirect to login or another appropriate route
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
};

export default CodeCheckRoute
