import axios from 'axios';
import store from '../redux/store'; // Import your Redux store
import { clearAccessToken, setAccessToken } from '../redux/userSlice';
import BASE_URL from '../config';

const TOKEN_REFRESH = 'token-refresh/';

// Create an Axios instance
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
// Variable to track if refresh token request is in progress
let isRefreshing = false;
let refreshSubscribers = [];
// Function to add access token to headers
const addAccessTokenToHeaders = (config) => {
  const accessToken = store.getState().user.accessToken;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

instance.interceptors.request.use(
  (config) => {
    return addAccessTokenToHeaders(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {


          const refreshToken = store.getState().user.refreshToken;
          const response = await axios.post(`${BASE_URL}/${TOKEN_REFRESH}`, { refresh: refreshToken });
          const newAccessToken = response.data.access_token;
          
          store.dispatch(setAccessToken(newAccessToken));
         
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Refresh token failed:', refreshError);
          store.dispatch(clearAccessToken())
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      } else {

        return new Promise((resolve) => {
          refreshSubscribers.push((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
    } else {
      if (error.response && error.response.status === 401) {
        store.dispatch(clearAccessToken());
      }
    }
    return Promise.reject(error);
  }
);
export default instance;
