import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/components_css/HeaderSection.css';
import { FaTimes, FaEllipsisV } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSelectionMode } from '../../redux/selectionSlice'; // Update the path as necessary


const HeaderSection = ({ icon, heading, text, Link: AddNewLink, Text: AddNewText, URL, closeButton, onClose, Show = false, className, menuButton, onMenuClick, showPostType, onPostTypeSelect  }) => {
  const dispatch = useDispatch();
  const isSelectionMode = useSelector((state) => state.selection.isSelectionMode);
  const [showSelectionButton, setShowSelectionButton] = useState(false);
  const [showPostTypeDropdown, setShowPostTypeDropdown] = useState(false);
  const IconComponent = icon;
  const headerRef = useRef(null); 
  const selectionButtonRef = useRef(null);
  const postTypeButtonRef = useRef(null);

  const handleMenuClick = () => {
    setShowSelectionButton((prevState) => !prevState);

  };

  const handleToggleSelection = () => {
    dispatch(toggleSelectionMode());
  };

  const handlePostTypeClick = () => {
    setShowPostTypeDropdown((prevState) => !prevState);
  };

  const handlePostTypeSelect = (postType) => {
    if (onPostTypeSelect) {
      onPostTypeSelect(postType);
    }
    setShowPostTypeDropdown(false);
  };

  const handleClickOutside = (event) => {
    if (
      selectionButtonRef.current &&
      !selectionButtonRef.current.contains(event.target) &&
      !headerRef.current.contains(event.target)
    ) {
      setShowSelectionButton(false);
    }
    if (
      postTypeButtonRef.current &&
      !postTypeButtonRef.current.contains(event.target) &&
      !headerRef.current.contains(event.target)
    ) {
      setShowPostTypeDropdown(false);
    }
  };

  useEffect(() => {
    if (showSelectionButton || showPostTypeDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSelectionButton, showPostTypeDropdown]);

  return (
    <div className={`header-section ${className}`} ref={headerRef}>
      <div className='header-head'>
        <div className="icon">
          {IconComponent && <IconComponent />}
        </div>
        <div className="content">
          <h1>{heading}</h1>
          <p>{text}</p>
        </div>
      </div>

      {menuButton && (
        <div className={`menu-button ${showSelectionButton ? 'active' : ''}`} onClick={handleMenuClick}>
          <FaEllipsisV />
        </div>
      )}

      {showSelectionButton && (
        <button onClick={handleToggleSelection} className="selection-mode-button" ref={selectionButtonRef}>
          {isSelectionMode ? 'Cancel' : 'Select Messages'}
        </button>
      )}

      {Show && (
        <Link to={URL}>
          <div className='add-new'>
            {IconComponent && <IconComponent />}
            {AddNewText}
          </div>
        </Link>
      )}

      {closeButton && (
        <div className="close-button" onClick={onClose}>
          <FaTimes />
        </div>
      )}
     {showPostType && (
        <div className="post-type">
          <button className='btn btn-primary' onClick={handlePostTypeClick} ref={postTypeButtonRef}>
            Post Type
          </button>
          {showPostTypeDropdown && (
            <div className="post-type-dropdown">
              <ul>
                <li onClick={() => handlePostTypeSelect('story')}>Add Story</li>
                <li onClick={() => handlePostTypeSelect('reel')}>Reels</li>
                <li onClick={() => handlePostTypeSelect('video')}>Videos</li>
                <li onClick={() => handlePostTypeSelect('multiple')}>Multiple Images or Videos</li>
                <li onClick={() => handlePostTypeSelect('single')}>Single Post</li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

HeaderSection.propTypes = {
  icon: PropTypes.elementType.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  Show: PropTypes.bool,
  Link: PropTypes.elementType,
  Text: PropTypes.string,
  URL: PropTypes.string,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  onClose: PropTypes.func,
  menuButton: PropTypes.bool,
  onMenuClick: PropTypes.func,
  isSelectionMode: PropTypes.bool,
  toggleSelectionMode: PropTypes.func,
  showPostType: PropTypes.bool,
  onPostTypeSelect: PropTypes.func,
};

export default HeaderSection;

