import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSiteSettings, updateSiteSettingsApi } from '../services/siteSettingsService';

export const getSiteSettings = createAsyncThunk(
    'siteSettings/getSiteSettings',
    async (_, { rejectWithValue }) => {
        try {
            const settings = await fetchSiteSettings();
            return settings;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateSiteSettings = createAsyncThunk(
    'siteSettings/updateSiteSettings',
    async (formData, { rejectWithValue }) => {
        try {
            const updatedSettings = await updateSiteSettingsApi(formData);
            return updatedSettings;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const siteSettingsSlice = createSlice({
    name: 'siteSettings',
    initialState: {
        settings: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSiteSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSiteSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
                state.loading = false;
            })
            .addCase(getSiteSettings.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateSiteSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSiteSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
                state.loading = false;
            })
            .addCase(updateSiteSettings.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export default siteSettingsSlice.reducer;
