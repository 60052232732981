import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from '../components/Chat/api'
export const fetchNotifications = createAsyncThunk(
    'notification/fetchNotifications',
    async (accessToken, { rejectWithValue }) => {
        try {
            const response = await api.get('/notifications', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        unreadCount: 0,
        notifications: [],
        systemMessages: [],
        status: 'idle',
        error: null
    },
    reducers: {
        addNotification: (state, action) => {
            state.notifications.unshift(action.payload)
        },
        clearNotification: (state) => {
            state.notifications = []
        },
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload
        },
        incrementUnreadCount: (state, action) => {
            state.unreadCount += action.payload
        },
        addSystemMessage: (state, action) => { // Add this reducer to handle system messages
            state.systemMessages.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.notifications = action.payload
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error
            })
    }
})
export const { addNotification, clearNotification, setUnreadCount, incrementUnreadCount, addSystemMessage } = notificationSlice.actions;
export const selectUnreadCount = (state) => state.notification.unreadCount;
export const selectNotifications = (state) => state.notification.notifications;
export const selectSystemMessages = (state) => state.notification.systemMessages;
export default notificationSlice.reducer;