import React, { useState } from 'react';
import { MdArrowForwardIos } from "react-icons/md";
import '../../assets/components_css/HelpComponent.css';
import { FaHandsHelping } from 'react-icons/fa';
import Layout from '../GeneralComponents/Layout';

const HelpComponent = () => {
  const [openMenus, setOpenMenus] = useState({
    introduction: false,
    tutorials: false,
    usingReactRedux: false,
    apiReference: false,
    guides: false,
  });

  const toggleMenu = (menu) => {
    setOpenMenus({
      ...openMenus,
      [menu]: !openMenus[menu],
    });
  };

  return (
    <Layout
      icon={FaHandsHelping}
      heading="Help Center"
    >
      <div className="help-container">
        <nav className="help-nav">
          <ul>
            <li>
              <div className="menu-title" onClick={() => toggleMenu('introduction')}>
                Introduction <MdArrowForwardIos className={`menu-icon ${openMenus.introduction ? 'open' : ''}`} />
              </div>
              {openMenus.introduction && (
                <ul>
                  <li><a href="#getting-started">Getting Started</a></li>
                  <li><a href="#why-use-redux">Why Use React Redux?</a></li>
                </ul>
              )}
            </li>
            <li>
              <div className="menu-title" onClick={() => toggleMenu('tutorials')}>
                Tutorials <MdArrowForwardIos className={`menu-icon ${openMenus.tutorials ? 'open' : ''}`} />
              </div>
              {openMenus.tutorials && (
                <ul>
                  <li><a href="#quick-start">Quick Start</a></li>
                  <li><a href="#ts-quick-start">TypeScript Quick Start</a></li>
                  <li><a href="#connect-api">Tutorial: Connect API</a></li>
                </ul>
              )}
            </li>
            <li>
              <div className="menu-title" onClick={() => toggleMenu('usingReactRedux')}>
                Using React Redux <MdArrowForwardIos className={`menu-icon ${openMenus.usingReactRedux ? 'open' : ''}`} />
              </div>
              {openMenus.usingReactRedux && (
                <ul>
                  <li><a href="#usage-ts">Usage with TypeScript</a></li>
                  <li><a href="#map-state">Connect: Extracting Data</a></li>
                  <li><a href="#map-dispatch">Connect: Dispatching Actions</a></li>
                  <li><a href="#access-store">Accessing the Store</a></li>
                </ul>
              )}
            </li>
            <li>
              <div className="menu-title" onClick={() => toggleMenu('apiReference')}>
                API Reference <MdArrowForwardIos className={`menu-icon ${openMenus.apiReference ? 'open' : ''}`} />
              </div>
              {openMenus.apiReference && (
                <ul>
                  <li><a href="#reference1">Reference 1</a></li>
                  <li><a href="#reference2">Reference 2</a></li>
                </ul>
              )}
            </li>
            <li>
              <div className="menu-title" onClick={() => toggleMenu('guides')}>
                Guides <MdArrowForwardIos className={`menu-icon ${openMenus.guides ? 'open' : ''}`} />
              </div>
              {openMenus.guides && (
                <ul>
                  <li><a href="#guide1">Guide 1</a></li>
                  <li><a href="#guide2">Guide 2</a></li>
                </ul>
              )}
            </li>
          </ul>
        </nav>
        <div className="help-content">
          <section id="getting-started">
            <h1>Getting Started</h1>
            {/* Content for Getting Started */}
          </section>
          <section id="why-use-redux">
            <h1>Why Use React Redux?</h1>
            {/* Content for Why Use React Redux */}
          </section>
          <section id="quick-start">
            <h1>Quick Start</h1>
            {/* Content for Quick Start */}
          </section>
          <section id="ts-quick-start">
            <h1>TypeScript Quick Start</h1>
            {/* Content for TypeScript Quick Start */}
          </section>
          <section id="connect-api">
            <h1>Tutorial: Using the connect API</h1>
            <div className="tip">
              <p><strong>TIP:</strong> We now recommend using the React-Redux hooks API as the default. However, the <code>connect</code> API still works fine.</p>
            </div>
            <p>This tutorial also shows some older practices we no longer recommend, like separating Redux logic into folders by type. We've kept this tutorial as-is for completeness, but recommend reading through the <a href="#redux-essentials">"Redux Essentials"</a> tutorial and the <a href="#redux-style-guide">Redux Style Guide</a> in the Redux docs for our current best practices.</p>
            <p>We're working on a new tutorial that will introduce the hooks APIs. Until then, we suggest reading <a href="#redux-fundamentals">Redux Fundamentals, Part 5: UI and React</a> for a hooks tutorial.</p>
            <p>To see how to use React Redux in practice, we'll show a step-by-step example by creating a todo list app.</p>
          </section>
          <section id="usage-ts">
            <h1>Usage with TypeScript</h1>
            {/* Content for Usage with TypeScript */}
          </section>
          <section id="map-state">
            <h1>Connect: Extracting Data</h1>
            {/* Content for Connect: Extracting Data */}
          </section>
          <section id="map-dispatch">
            <h1>Connect: Dispatching Actions</h1>
            {/* Content for Connect: Dispatching Actions */}
          </section>
          <section id="access-store">
            <h1>Accessing the Store</h1>
            {/* Content for Accessing the Store */}
          </section>
          <section id="reference1">
            <h1>Reference 1</h1>
            {/* Content for Reference 1 */}
          </section>
          <section id="reference2">
            <h1>Reference 2</h1>
            {/* Content for Reference 2 */}
          </section>
          <section id="guide1">
            <h1>Guide 1</h1>
            {/* Content for Guide 1 */}
          </section>
          <section id="guide2">
            <h1>Guide 2</h1>
            {/* Content for Guide 2 */}
          </section>
        </div>
        <nav className="help-sidebar">
          <h2>Table of Contents</h2>
          <ul>
            <li><a href="#getting-started">Getting Started</a></li>
            <li><a href="#why-use-redux">Why Use React Redux?</a></li>
            <li><a href="#quick-start">Quick Start</a></li>
            <li><a href="#ts-quick-start">TypeScript Quick Start</a></li>
            <li><a href="#connect-api">Using the connect API</a></li>
            <li><a href="#usage-ts">Usage with TypeScript</a></li>
            <li><a href="#map-state">Connect: Extracting Data</a></li>
            <li><a href="#map-dispatch">Connect: Dispatching Actions</a></li>
            <li><a href="#access-store">Accessing the Store</a></li>
            <li><a href="#reference1">Reference 1</a></li>
            <li><a href="#reference2">Reference 2</a></li>
            <li><a href="#guide1">Guide 1</a></li>
            <li><a href="#guide2">Guide 2</a></li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
};

export default HelpComponent;
