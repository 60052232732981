import React, { useState, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { adminUserManagementRoutes, adminUtilityRoutes } from '../../utils/utils';
import '../../assets/components_css/AdminSideBar.css';
import { FaUser, FaChevronUp, FaChevronDown } from 'react-icons/fa';

const AdminSidebar = ({ isAdminSidebarOpen, toggleAdminSidebar }) => {
  const location = useLocation();

  const showUsersDefault = useMemo(
    () =>
      location.pathname === '/' ||
      location.pathname === '/marketing-managers' ||
      location.pathname === '/account-managers',
    [location.pathname]
  );

  const [showUsers, setShowUsers] = useState(showUsersDefault);
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (key) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className={`admin-sidebar ${isAdminSidebarOpen ? 'open' : ''}`}>
      <div className="sidebar-item usos" onClick={() => setShowUsers(!showUsers)}>
        <span className="user-icn">
          <FaUser />
        </span>
        <div className="side-users">
          Users
          {showUsers ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {showUsers && (
        <ul className="sidebar-submenu">
          {adminUserManagementRoutes.map((route) => (
            <li key={route.path}>
              <NavLink to={route.path}>{route.name}</NavLink>
            </li>
          ))}
        </ul>
      )}
      {adminUtilityRoutes.map((route) => (
        <div key={route.path} className="sidebar-item">
          <NavLink to={route.path}>
            <span className="user-icn">{route.icon}</span>
            {route.name}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default AdminSidebar;
