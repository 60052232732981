import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './../api/axios';

const FB_DATA = 'facebook-data/'

export const fetchFacebookLiveData = createAsyncThunk(
    'facebook/fetchLiveData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(FB_DATA);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const facebookLiveDataSlice = createSlice({
    name: 'facebookLiveData',
    initialState: {
        userInfo: null,
        businessAccounts: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacebookLiveData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFacebookLiveData.fulfilled, (state, action) => {
                state.userInfo = action.payload.user_info;
                state.businessAccounts = action.payload.business_accounts;
                state.loading = false;
            })
            .addCase(fetchFacebookLiveData.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default facebookLiveDataSlice.reducer;
