import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '../api/axios'

const ASSIGN_USERS_URL = 'role-request/'
const LINKED_USERS_URL = 'linked-users/'
const USER_PAGES_URL = 'facebook-user/'

export const fetchUsersByRole = createAsyncThunk(
    'search/fetchUsers',
    async ({ role, query }) => {
        const response = await axios.post(ASSIGN_USERS_URL, { role, search: query })
        return { role, users: response.data }
    }
)

export const fetchLinkedUsers = createAsyncThunk(
    'search/fetchLinkedUsers',
    async (searchQuery) => {
        const response = await axios.get(LINKED_USERS_URL, { search: searchQuery });
        return response.data;
    }
)

export const fetchLinkedUserDetails = createAsyncThunk(
    'search/fetchLinkedUserDetails',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${LINKED_USERS_URL}${userId}/`)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const fetchUserPages = createAsyncThunk(
    'search/fetchUserPages',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${USER_PAGES_URL}${userId}/`)
            const pages = response.data.map(item => item.pages).flat();
            return pages
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        users: {
            user: [],
            account_manager: [],
            marketing_manager: []
        },
        selectedUsers: {
            user: null,
            account_manager: null,
            marketing_manager: null
        },
        linkedUsers: [],
        detailedUser: null,
        pages: [],
        status: 'idle',
        error: null
    },
    reducers: {
        selectUser: (state, action) => {
            const { role, user } = action.payload
            state.selectedUsers[role] = user
        },
        clearSearchResults: (state, action) => {
            const role = action.payload
            state.users[role] = []
        },
        clearSelectedUsers: (state) => {
            state.selectedUsers = {
                user: null,
                account_manager: null,
                marketing_manager: null
            }
        },
        clearLinkedUsersSearchResults: (state) => {
            state.linkedUsers = [];
        },
        clearPagesData: (state) => {
            state.pages = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersByRole.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUsersByRole.fulfilled, (state, action) => {
                const { role, users } = action.payload;
                state.status = 'succeeded';
                state.users[role] = users;
            })
            .addCase(fetchUsersByRole.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchLinkedUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLinkedUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.linkedUsers = action.payload;
            })
            .addCase(fetchLinkedUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchLinkedUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLinkedUserDetails.fulfilled, (state, action) => {
                state.detailedUser = action.payload;
            })
            .addCase(fetchLinkedUserDetails.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(fetchUserPages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserPages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.pages = action.payload;
            })
            .addCase(fetchUserPages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
})

export const { selectUser, clearSearchResults, clearSelectedUsers, clearLinkedUsersSearchResults, clearPagesData } = searchSlice.actions

export default searchSlice.reducer