import React from 'react';
import PersonIcon from '../../assets/Images/PersonIcon.svg';
import brandMark from '../../assets/Images/Brand_mark.png';
import BASE_URL from '../../config';

import phoneImage from '../../assets/Images/phone.svg';
import emailImage from '../../assets/Images/email.svg';
import meetImage from '../../assets/Images/meet.png';

const AccountManagerDetails = ({ accountManager }) => {

    const profile_picture = accountManager?.profile_picture
    const first_name = accountManager?.first_name
    const last_name = accountManager?.last_name
    const phone_number = accountManager?.phone_number
    const email = accountManager?.email
    const meeting_link = accountManager?.meeting_link
    const profileSrc = profile_picture ? `${BASE_URL}/${profile_picture}` : PersonIcon;
    const meeting_link_src = meeting_link || "http://defaultmeetinglink.com";
    const first_name_account = first_name || "Danish";
    const last_name_account = last_name || "Kaleem";
    const phone_number_account = phone_number || "0123456789";
    const email_account = email || "info@example.com";

    return (
        <>
            <div className="col">
                <div className="usr-bx-gnrl acc-mnger side-bar-on">
                    <div className='user-inf'>
                        <div className="usr-img account-manager">
                            <img src={profileSrc} alt={`${first_name_account} ${last_name_account}`} />
                        </div>

                        <div className="usr-nme">
                            <h6>{first_name_account} {last_name_account}</h6>
                            <p>SMM Experts</p>
                        </div>
                    </div>

                    <div className="usr-icons">
                        <div className="acc-ico eml-ico">
                            <a href={`tel:${phone_number_account}`}>{phone_number_account}
                                {/* <img src={phoneImage} alt="phone" /> */}
                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5 0.328125C10.0278 0.328125 7.61101 1.06124 5.55539 2.43476C3.49977 3.80828 1.89761 5.76052 0.951513 8.0446C0.00541619 10.3287 -0.242126 12.842 0.24019 15.2668C0.722506 17.6916 1.91302 19.9188 3.66118 21.667C5.40934 23.4152 7.63663 24.6057 10.0614 25.088C12.4862 25.5703 14.9995 25.3228 17.2836 24.3767C19.5677 23.4306 21.5199 21.8284 22.8934 19.7728C24.2669 17.7172 25.0001 15.3004 25.0001 12.8281C25.0001 9.51294 23.6831 6.33351 21.3389 3.9893C18.9947 1.64509 15.8152 0.328125 12.5 0.328125ZM13.0359 8.00647C14.1721 8.0078 15.2615 8.45976 16.065 9.26323C16.8684 10.0667 17.3204 11.156 17.3217 12.2923H16.25C16.2492 11.4401 15.9102 10.6231 15.3077 10.0205C14.7051 9.41794 13.888 9.07902 13.0359 9.07814V8.00647ZM16.5067 20.3282C16.4742 20.3282 13.2567 20.2732 9.11919 16.209C5.05502 12.0731 5.00002 8.85398 5.00002 8.82148C5.00002 8.42064 7.50002 5.32814 8.05252 5.32814C8.24419 5.32814 8.39335 5.45814 8.55669 5.65314C8.95002 6.12147 10.6892 8.86648 10.6092 9.32398C10.5709 9.53898 10.3884 9.70231 9.86502 10.1415C9.60716 10.3442 9.36584 10.5671 9.14336 10.8081C9.2499 11.2491 9.40823 11.676 9.61502 12.0798C10.3855 13.6639 11.6656 14.9434 13.25 15.7132C13.6538 15.92 14.0807 16.0783 14.5217 16.1848C14.7627 15.9623 14.9856 15.721 15.1884 15.4632C15.6275 14.9398 15.7909 14.7573 16.0059 14.719C16.4634 14.6357 19.2084 16.3782 19.6767 16.7715C19.8717 16.9382 20.0017 17.084 20.0017 17.2757C20 17.8282 16.9075 20.3282 16.5067 20.3282ZM18.9284 12.2923C18.9266 10.7301 18.3052 9.23231 17.2006 8.12763C16.0959 7.02295 14.5981 6.40157 13.0359 6.3998V5.32814C14.8822 5.33034 16.6523 6.06477 17.9578 7.37033C19.2634 8.67589 19.9978 10.446 20 12.2923H18.9284Z" fill="var(--secondary-color)" />
                                </svg>
                            </a>
                            <a href={`mailto:${email_account}`}>{email_account}
                                {/* <img src={emailImage} alt="email" /> */}
                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0.328125C19.4036 0.328125 25 5.92456 25 12.8281C25 19.7317 19.4036 25.3281 12.5 25.3281C5.59644 25.3281 0 19.7317 0 12.8281C0 5.92456 5.59644 0.328125 12.5 0.328125ZM20.4346 17.6739V8.38369L15.7892 13.0288L20.4346 17.6739ZM5.55781 17.8631H19.4425L15.1989 13.6196L13.5816 15.2367C13.5032 15.3149 13.3969 15.3588 13.2862 15.3587H11.7139C11.6591 15.3588 11.6047 15.348 11.554 15.3271C11.5033 15.3061 11.4573 15.2754 11.4185 15.2367L9.80117 13.6196L5.55762 17.8631H5.55781ZM4.56543 8.3834V17.6741L9.21079 13.0288L4.56543 8.3834ZM19.844 7.79312H5.15625L11.8869 14.5238H13.1133L19.844 7.79312Z" fill="var(--secondary-color)" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className="acc-mngr-lbl">
                        <h6>Account Manager</h6>
                    </div>

                    <div className="col">
                        <div className="schdle-mting-wrapper sd-meed">
                            <a href={meeting_link_src} target='_blank' rel="noreferrer" className='schdle-mting-btn-side'>
                                <div className="schdle-mting-btn-txt shd-meet">
                                    <h5>Schedule a Meeting</h5>
                                    <p>Powered by <img src={brandMark} alt="brandMark" /></p>
                                </div>

                                <div className="schdle-mting-icon">
                                    <img src={meetImage} alt="meet" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AccountManagerDetails;