import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './../api/axios';
import { toast } from 'react-toastify';

const FB_STATUS = 'fb-login-status/';
const FB_LOGOUT = 'fb-logout/';

export const checkFacebookLoginStatus = createAsyncThunk(
    'facebook/checkStatus',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${FB_STATUS}${userId}/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)


// New action to remove access
export const removeAccess = createAsyncThunk(
    'facebook/removeAccess',
    async (userId, { rejectWithValue }) => {
        try {
            await axios.post(`/remove-access/${userId}/`);
            return false; // Return false to update giveAccess
        } catch (error) {
            toast.error('Failed to remove access.');
            return rejectWithValue(error.response.data);
        }
    }
)


export const logout = createAsyncThunk(
    'facebook/logout',
    async (_, { rejectWithValue }) => {
        try {
            await axios.post(FB_LOGOUT);
            toast.success('Logged out successfully!');
            return true;
        } catch (error) {
            toast.error('Failed to logout. Please try again.');
            return rejectWithValue(error.response.data);
        }
    }
);

const facebookSlice = createSlice({
    name: 'facebook',
    initialState: {
        isFBLoggedIn: false,
        giveAccess: false,
        loading: false,
        error: null,
        logoutSuccess: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkFacebookLoginStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkFacebookLoginStatus.fulfilled, (state, action) => {
                state.isFBLoggedIn = action.payload.is_fb_logged_in;
                state.giveAccess = action.payload.give_access;
                state.loading = false;
            })
            .addCase(checkFacebookLoginStatus.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isFBLoggedIn = false;
                state.giveAccess = false;
                state.loading = false;
                state.logoutSuccess = true;
            })
            .addCase(logout.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default facebookSlice.reducer;
