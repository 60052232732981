import axios from 'axios';
import { CHAT_BASE_URL_API } from '../../config';


const api = axios.create({
    baseURL: CHAT_BASE_URL_API,
    withCredentials: true,
});

let csrfToken = null;
let fetchingCsrfToken = null;

// Function to get CSRF token from backend
async function fetchCsrfToken() {
    if (csrfToken) {
        return csrfToken;
    }
    if (fetchingCsrfToken) {
        return fetchingCsrfToken;
    }
    fetchingCsrfToken = api.get('/csrf-token')
        .then(response => {
            csrfToken = response.data.csrfToken;
            document.cookie = `XSRF-TOKEN=${csrfToken}; SameSite=Strict`;
            fetchingCsrfToken = null;
            return csrfToken;
        })
        .catch(error => {
            console.error('Error fetching CSRF token:', error);
            fetchingCsrfToken = null;
            throw error;
        });
    return fetchingCsrfToken;
}

// Add a request interceptor
api.interceptors.request.use(async (config) => {
    const excludedPaths = ['/notifications', '/subscriptions', '/csrf-token'];


    const isExcluded = excludedPaths.some(path => config.url.includes(path));

    if (!isExcluded && !config.headers['X-CSRF-Token']) {
        try {
            const csrfToken = await fetchCsrfToken();
            config.headers['X-CSRF-Token'] = csrfToken;
        } catch (error) {
            console.error('Failed to fetch CSRF token:', error);
        }
    }
    return config;
}, (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
});

export const fetchMessages = async (senderId, receiverId, page, accessToken, setMessages, setHasMoreMessages, setError, setIsLoading) => {
    try {
        const response = await api.get('/messages', {
            params: {
                senderId,
                receiverId,
                page,
                limit: 20, // Assuming limit is fixed
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const fetchedMessages = response.data;
        if (fetchedMessages.length < 20) {
            setHasMoreMessages(false);
        }
        setMessages(prevMessages => {
            const updatedMessages = [...fetchedMessages, ...prevMessages];
            updatedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            return updatedMessages;
        });
    } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to load messages. Please try again later.');
    } finally {
        setIsLoading(false);
    }
}


export const uploadFile = async (file, senderId, receiverId, message, accessToken) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('sender_id', senderId);
        formData.append('receiver_id', receiverId);
        formData.append('message', message);

        const response = await api.post('/upload', formData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const markMessagesAsRead = async (senderId, accessToken) => {
    try {
        await api.post('/mark-as-read', { senderId }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    } catch (error) {
        console.error('Error marking messages as read:', error);
        throw error;
    }
};

export const deleteMessages = async (messageIds, accessToken) => {
    try {
        const response = await api.delete('/delete-messages', {
            data: { messageIds },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting messages:', error);
        throw error;
    }
};

export const editMessage = async (messageId, newMessage, userId, accessToken) => {
    try {
        const response = await api.put('/edit-message', {
            messageId,
            newMessage,
            userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error editing message:', error);
        throw error;
    }
};



export default api;
