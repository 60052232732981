import api from './../components/Chat/api'
export const subscribeUserToPush = async (userId) => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
            return existingSubscription;
        }

        const response = await api.get('/public-key');
        const { publicKey } = response.data;

        const applicationServerKey = urlBase64ToUint8Array(publicKey);
        const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey
        });

        // Save subscription using the api module
        await api.post('/subscribe', {
            userId,
            subscription: newSubscription
        });

        return newSubscription;
    } else {
        console.error('Push messaging is not supported');
        return null;
    }
};

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};
