import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../GeneralComponents/SideBar';
import HeaderSection from '../HeaderComponents/HeaderSection';
import '../../assets/components_css/Layout.css';
import { Link, useLocation } from 'react-router-dom';
import AdminSidebar from '../AdminComponents/AdminSidebar';
import Loading from '../UserManagement/Loading'; // Assuming you have a Loading component
import { useSelector, useDispatch } from 'react-redux';
import { toggleSelectionMode } from '../../redux/selectionSlice'; // Update the path as necessary



const Layout = ({ icon, heading, text, Link: AddNewLink, Text: AddNewText, URL, children, Show, isAdmin, isUser, className = '', customHeading }) => {
    const [loading, setLoading] = useState(true);
    const hasSidebar = isAdmin || isUser;
    const location = useLocation();
    const dispatch = useDispatch();
    const isSelectionMode = useSelector((state) => state.selection.isSelectionMode);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleOnMenuClick  = () => {
        dispatch(toggleSelectionMode());
    };

    const showMenuButton = location.pathname === '/chat';

    return (
        <Container>
            <Row>
                <Col md={isAdmin ? 2 : 2}>
                    {isAdmin ? <AdminSidebar /> : isUser ? <Sidebar /> : null}
                </Col>
                <Col md={12} xl={isAdmin ? 10 : hasSidebar ? 10 : 12} lg={12} className="layout">
                    <HeaderSection
                        icon={icon}
                        heading={customHeading ? customHeading : <h1>{heading}</h1>}
                        text={text}
                        Show={Show}
                        Link={Link}
                        Text={AddNewText}
                        URL={URL}
                        className={className}
                        menuButton={showMenuButton}
                        onMenuClick={handleOnMenuClick}
                        isSelectionMode={isSelectionMode}
                        toggleSelectionMode={() => dispatch(toggleSelectionMode())}
                    />
                    <div className='childern-contents'>
                        {loading ? <Loading /> : children}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;

