import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios'; // Ensure this path is correct

// Assuming CART_ENDPOINT is correct and points to your backend endpoint
const CART_ENDPOINT = 'cart-items/';

// Define the initial state of the cart slice
const initialState = {
  cartItem: null,
  total: 0,
  loading: false,
  error: null,
};

// Define a thunk for fetching cart items
export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`${CART_ENDPOINT}${userId}/`);
      // Assuming the response data is the cart item you're interested in
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItem: (state, action) => {
      state.cartItem = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    clearCart: (state) => {
      state.cartItem = null;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.loading = false;
        state.cartItem = action.payload;
        // Calculate and set the total here if needed, or keep the calculation separate
        state.total = action.payload.custom_plan
          ? action.payload.custom_plan.price_monthly
          : action.payload.plan
            ? action.payload.plan.price_monthly
            : 0; // Adjust this calculation as necessary
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default cartSlice.reducer;

// Export the actions
export const { setCartItem, setTotal, clearCart } = cartSlice.actions;

// Export selectors
export const selectCartItem = (state) => state.cart.cartItem;
export const selectCartTotal = (state) => state.cart.total;
