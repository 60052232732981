const UserDropdownMenu = ({ userData, handleLogout, Link }) => (
    <div className="drpdwn-mnu-wrapper">
        <div className="drpdwn-mnu-cntnt">
            <ul>
                <li>
                    <Link to="/account-settings">Account Settings</Link>
                </li>
                {userData.role === 'user' && (
                    <>
                        <li>
                            <Link to="/payment-settings">Payment Settings</Link>
                        </li>
                        <li>
                            <Link to="/packages">Packages</Link>
                        </li>
                        {/* <li>
                            <Link to="/ad-spendings-packages">Ads Spendings</Link>
                        </li> */}
                    </>
                )}
                {userData.role === 'account_manager' && (
                    <>
                        <li>
                            <Link to="/custom-packages">Custom Packages</Link>
                        </li>
                        <li>
                            <Link to="/custom-coupons">Custom Coupons</Link>
                        </li>
                        <li>
                            <Link to="/ad-spendings">Ads Spendings</Link>
                        </li>
                    </>
                )}
                <li>
                    <button onClick={handleLogout}>Logout</button>
                </li>
            </ul>
        </div>
    </div>
);
export default UserDropdownMenu









