import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../api/axios'

const USER_ACCOUNT_MANAGER = 'user-account-manager/'

export const fetchAccountManager = createAsyncThunk(
    'userAccountManager/fetchAccountManager',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(USER_ACCOUNT_MANAGER);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

// Initial state
const initialState = {
    accountManager: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
};

// Slice
export const userAccountManagerSlice = createSlice({
    name: 'userAccountManager',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountManager.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccountManager.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.accountManager = action.payload;
            })
            .addCase(fetchAccountManager.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error : 'Cannot fetch account manager';
            });
    },
});

export default userAccountManagerSlice.reducer;
