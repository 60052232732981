import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
const FBRoute = ({ children, requireAccess = false }) => {
    const location = useLocation();
    const isFBLoggedIn = useSelector((state) => state.facebook.isFBLoggedIn);
    const giveAccess = useSelector((state) => state.facebook.giveAccess);
    if (!isFBLoggedIn) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    if (requireAccess && !giveAccess) {
        return <Navigate to="/meta-dashboard" state={{ from: location }} replace />;
    }
    return children;
};
export default FBRoute;