import axios from 'axios';
import api from '../api/axios';
import BASE_URL from '../config';



export const fetchSiteSettings = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/site-settings/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching site settings:", error);
        throw error;
    }
};


export const updateSiteSettingsApi = async (formData) => {
    try {
        const response = await api.patch('site-settings/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching site settings:", error);
        throw error;
    }
};
