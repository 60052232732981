import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/components_css/PrivacyPolicy.css';
import Layout from '../GeneralComponents/Layout';
import { MdDesignServices } from 'react-icons/md';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

const TermsOfService = () => {
    const settings = useSelector((state) => state.siteSettings.settings)
    return (
        <Layout
            icon={MdDesignServices}
            heading="Terms of Services"
            text="Effective Date: 01-01-2024"
        >
            <Container>
                <section className="privacy-policy-section firt-sect " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.terms_service) }}>

                </section>

            </Container>
        </Layout>
    );
};

export default TermsOfService;
