import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaUser } from "react-icons/fa";
import Layout from '../../GeneralComponents/Layout';
import { selectUserData } from '../../../redux/userSlice';
import '../../../assets/components_css/social.css';
import Loading from '../../UserManagement/Loading';
import { removeAccess } from '../../../redux/facebookSlice';

const FB_CONNECT_ASSETS = '/facebook-user/';

const ConnectedMetaAssets = () => {
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const userId = userData?.id;
    const userInfo = useSelector((state) => state.facebookLiveData.userInfo);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingViewCalendar, setLoadingViewCalendar] = useState(false);
    const [loadingRemoveAccessId, setLoadingRemoveAccessId] = useState(null);
    const [error, setError] = useState(null);
    const isUser = userData?.role === 'user';
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) return;
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${FB_CONNECT_ASSETS}${userId}/`);
                setData(response.data);
            } catch (error) {
                setError('Failed to fetch data');
                toast.error('Failed to fetch data');
                navigate('/'); // Navigate to home page on error
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [userId, navigate]);

    const handleViewCalendar = (pageId) => {
        localStorage.setItem('calendarPageId', pageId);
        setLoadingViewCalendar(true);
        setTimeout(() => {
            navigate(`/`);
        });
    };

    const handleRemoveAccess = async (businessId) => {
        setLoadingRemoveAccessId(businessId);
        try {
            await dispatch(removeAccess(businessId)).unwrap();
            localStorage.removeItem('givenAccess');
            localStorage.removeItem(`disabledState_${userInfo.id}`);
            toast.success('Access removed successfully!');
            navigate('/meta-dashboard');
        } catch (error) {
            toast.error('Failed to remove access.');
        } finally {
            setLoadingRemoveAccessId(null);
        }
    };

    const hasValidInstagramAccounts = (accounts) => {
        return accounts && accounts.length > 0 && accounts.some(account =>
            account['Instagram Business ID'] || account['Instagram Business Name'] || account['Instagram Profile Picture']
        );
    };

    const hasAssets = data && (data.business || (data.pages && data.pages.length > 0) || hasValidInstagramAccounts(data.instagram_accounts) || (data.ad_accounts && data.ad_accounts.length > 0));

    return (
        <div className='user-screen'>
            <Layout
                icon={FaUser}
                heading="Assigned Assets"
                isUser={isUser}
            >
                {error ? (
                    <div>{error}</div>
                ) : (
                    <>
                        {loading ? (
                            <Loading />
                        ) : (
                            hasAssets ? (
                                <section>
                                    {data.business && (
                                        <div className="business">
                                            <h1 className='connect-profiles'>Assigned Business</h1>
                                            <div className='BP-Meta'>
                                                <div className='bussiness-img-info'>
                                                    <img src={data.business['Business Picture']} alt="Business" />
                                                    <div className='bissiness-infor'>
                                                        <h6>{data.business['Business Name']}</h6>
                                                        <p>@{data.business['Business ID']}</p>
                                                    </div>
                                                </div>
                                                <Button
                                                    className='remove-access'
                                                    variant="danger"
                                                    onClick={() => handleRemoveAccess(data.business['Business ID'])}
                                                    disabled={loadingRemoveAccessId === data.business['Business ID']}
                                                >
                                                    {loadingRemoveAccessId === data.business['Business ID'] ? (
                                                        <>
                                                            <span className='spinner'></span> Removing...
                                                        </>
                                                    ) : (
                                                        'Remove Access'
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {data.pages && data.pages.length > 0 && (
                                        <div className="business-accounts">
                                            <h2>Assigned Assets <span className='int-BP main'>(Pages)</span></h2>
                                            {data.pages.map((page, pageIndex) => (
                                                <div key={pageIndex} className="account">
                                                    <div className="account-header">
                                                        <div className='bussiness-img-info'>
                                                            <img src={page['Page Picture']} alt={page['Page Name']} className="account-image" />
                                                            <div className="account-info">
                                                                <h3>{page['Page Name']}</h3>
                                                                <p>@{page['Page ID']}</p>
                                                            </div>
                                                        </div>
                                                        <div className="account-actions">
                                                            <Button
                                                                className="btn btn-primary view-colend"
                                                                onClick={() => handleViewCalendar(page['Page ID'])}>
                                                                View Calendar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {hasValidInstagramAccounts(data.instagram_accounts) && (
                                        <div className="instagram-accounts">
                                            <h2>Linked Instagram Business Account</h2>
                                            {data.instagram_accounts.map((account, accountIndex) => (
                                                (account['Instagram Business ID'] || account['Instagram Business Name'] || account['Instagram Profile Picture']) && (
                                                    <div key={accountIndex} className="account">
                                                        <div className="account-header">
                                                            <div className='bussiness-img-info'>
                                                                <img src={account['Instagram Profile Picture']} alt={account['Instagram Business Name']} className="account-image" />
                                                                <div className="account-info">
                                                                    <h3>{account['Instagram Business Name']}</h3>
                                                                    <p>@{account['Instagram Business ID']}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    )}
                                    {data.ad_accounts && data.ad_accounts.length > 0 && (
                                        <div className="ad-accounts">
                                            <h2>Linked Ad Accounts</h2>
                                            {data.ad_accounts.map((account, accountIndex) => (
                                                <div key={accountIndex} className="account">
                                                    <div className="account-header">
                                                        <div className='bussiness-img-info'>
                                                            <div className="account-info">
                                                                <h3>{account['Ad Account Name']}</h3>
                                                                <p>@{account['Ad Account ID']}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </section>
                            ) : (
                                <p>No connected assets available.</p>
                            )
                        )}
                    </>
                )}
            </Layout>
        </div>
    );
};

export default ConnectedMetaAssets;
