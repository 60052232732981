import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaFacebook } from "react-icons/fa";
import BASE_URL from "../../config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectUserData, clearAccessToken } from '../../redux/userSlice';
import { Suspense } from 'react';
import Loading from '../UserManagement/Loading';
import AccountManagerDetails from '../GeneralComponents/AccountManagerDetails'
import CurrentPackageDisplay from '../GeneralComponents/CurrentPackageDisplay'
import { selectIsSubscriptionCreated, selectSubscriptionData } from '../../redux/subscriptionSlice'
import sideboxImage from '../../assets/Images/sidebox.svg';
import chatImage from '../../assets/Images/chat.svg';
import pinterestImage from '../../assets/Images/pinterest.svg';
import linkImage from '../../assets/Images/linkedin.svg';
import XImage from '../../assets/Images/twittwe.svg';
import instaImage from '../../assets/Images/insta.svg';
import tiktokImage from '../../assets/Images/tiktok.svg';
import { FaTimes } from 'react-icons/fa';
import '../../assets/components_css/Sidebar.css';
import adspendImage from '../../assets/Images/adspend.svg';
import { selectSystemMessages } from '../../redux/notificationSlice'
import UserInteractionSection from "./UserInteractionSection";
import { toast } from 'react-toastify'
import { addNotification, addSystemMessage, fetchNotifications, incrementUnreadCount, selectUnreadCount, setUnreadCount } from '../../redux/notificationSlice'
import NotificationTray from '../GeneralComponents/NotificationTray'
import { RiCoinsFill } from "react-icons/ri";

const FB_LOGIN = 'fb-login/';

const LinkedUsersPagesDropdownSearch = React.lazy(() => import('../AccountManager/LinkedUsersPagesDropdownSearch'))

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const [expandedPlatform, setExpandedPlatform] = useState(null);
  const isFBLoggedIn = useSelector((state) => state.facebook.isFBLoggedIn);
  const userInfo = useSelector((state) => state.facebookLiveData.userInfo);
  const [showDiv, setShowDiv] = useState(false);
  const loading = useSelector((state) => state.calendar.status === 'loading');
  const error = useSelector((state) => state.calendar.error);
  const giveAccess = useSelector((state) => state.facebook.giveAccess);
  const userData = useSelector(selectUserData)
  const subscriptionData = useSelector(selectSubscriptionData)
  const accountManager = useSelector(state => state.userAccountManager.accountManager)
  const isSubscriptionCreated = useSelector(selectIsSubscriptionCreated)
  const [showChat, setShowChat] = useState(true);
  const [isFlex, setIsFlex] = useState(true);
  const [showText, setShowText] = useState(false);
  const [btnWidth, setBtnWidth] = useState('45px');
  const [isClicked, setIsClicked] = useState(false);
  const systemMessages = useSelector(selectSystemMessages);

  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isNotificationTrayOpen, setIsNotificationTrayOpen] = useState(false);
  const unreadCount = useSelector(selectUnreadCount);

  useEffect(() => {
    const chatShown = localStorage.getItem('chatShown');
    if (chatShown) {
      setShowChat(false);
      setIsFlex(false);
      setShowText(true);
      setBtnWidth('200px');
    } else {
      localStorage.setItem('chatShown', 'true');
      setShowChat(true);
      setIsFlex(true);
      setShowText(false);
      setBtnWidth('45px');
    }
  }, []);

  const platforms = [
    { name: 'Facebook', username: 'facebook_user', icon: FaFacebook },
    // { name: 'Login With Twitter', username: 'twitter_user', icon: FaXTwitter },
    // { name: 'Login With Instagram', username: 'instagram_user', icon: FaInstagram },
    // Add more social platforms as needed
  ];

  const handleFBLogin = useCallback(() => {
    if (isFBLoggedIn) {
      setShowDiv(!showDiv);
    } else {
      window.location.href = `${BASE_URL}/${FB_LOGIN}`;
    }
  }, [isFBLoggedIn, showDiv]);


  const isAnyPlatformConnected = isFBLoggedIn;

  // const handleClick = () => {
  //   setShowChat(true);
  //   setIsFlex(true);
  //   setShowText(false);
  //   setBtnWidth('45px');
  //   setIsClicked(true);
  // };

  const handleClose = () => {
    setShowChat(false);
    setShowText(true);
    setIsFlex(false);
    setBtnWidth('200px');
    setTimeout(() => setShowChat(false), 300);
  };

  const handleLogout = useCallback(() => {
    dispatch(clearAccessToken())
    localStorage.removeItem('selectedUserId')
    localStorage.removeItem('selectedUserPageId')
    toast.info('You have been logged out.')
  }, [dispatch]);

  const toggleDropdown = useCallback((state) => setIsDropdownOpen(state), []);
  const toggleNotificationTray = useCallback(() => setIsNotificationTrayOpen((prev) => !prev), []);
  const handleTrayClose = useCallback(() => setIsNotificationTrayOpen(false), []);

  return (
    <Suspense fallback={<Loading />}>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <h2>Accounts Center</h2>
        {platforms.map((platform, index) => (
          <div key={index}>
            <Suspense fallback={<Loading />}>
              <Link to={'/meta-dashboard'}>
                <button className="socials" onClick={handleFBLogin}>
                  <span className="soci-name">
                    {React.createElement(platform.icon)}
                    {isAnyPlatformConnected && userInfo ? userInfo.name : platform.name}
                  </span>
                  <img src={sideboxImage} alt="sideboxImage" />
                </button>
              </Link>
              <span className="other-socials">
                <button className="tooltip" title="Coming Soon">
                  <img src={linkImage} alt="chat" />
                </button>
                <button className="tooltip" title="Coming Soon">
                  <img src={pinterestImage} alt="chat" />
                </button>
                <button className="tooltip" title="Coming Soon">
                  <img src={XImage} alt="chat" />
                </button>
                <button className="tooltip" title="Coming Soon">
                  <img src={instaImage} alt="chat" />
                </button>
                <button className="tooltip" title="Coming Soon">
                  <img src={tiktokImage} alt="chat" />
                </button>
              </span>
            </Suspense>
          </div>
        ))}

        <div className="tablet-only">
          {userData?.role === 'user' && (
            <div className='col-0-5'>
              <div className='notification-icon' onClick={toggleNotificationTray}>
                {/* <img src={BellImage} alt="Notification" /> */}
                <svg width="27" height="33" viewBox="0 0 27 33" fill="var(--primary-color)" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.3883 22.9258C23.4114 21.2548 22.2778 18.8125 22.2778 16.225V12.5797C22.2778 7.97875 18.8601 4.17009 14.433 3.53204V2.11997C14.433 1.39694 13.8472 0.8125 13.1255 0.8125C12.4038 0.8125 11.818 1.39694 11.818 2.11997V3.53204C7.38962 4.17009 3.9732 7.97875 3.9732 12.5797V16.225C3.9732 18.8125 2.83962 21.2548 0.850954 22.9362C0.342347 23.3716 0.0507812 24.0044 0.0507812 24.6739C0.0507812 25.9356 1.07715 26.9619 2.33886 26.9619H23.9121C25.1738 26.9619 26.2002 25.9356 26.2002 24.6739C26.2002 24.0044 25.9086 23.3716 25.3883 22.9258Z" fill="var(--primary-color)" />
                  <path d="M13.1259 32.1919C15.4937 32.1919 17.4746 30.504 17.9296 28.2695H8.32227C8.77727 30.504 10.7581 32.1919 13.1259 32.1919Z" fill="var(--primary-color)" />
                </svg>
                <h6>Notifications</h6>
                {unreadCount > 0 && (
                  <span className='counter'>{unreadCount}</span>
                )}
              </div>
              {isNotificationTrayOpen && <NotificationTray onClose={handleTrayClose} />}
            </div>
          )}
        </div>

        <div className="tablet-only">
          {userData?.role === 'user' && (
            <UserInteractionSection
              Link={Link}
              userData={userData}
              isDropdownOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
              handleLogout={handleLogout}
            />
          )}
        </div>

        <div className="created-campaigns">
          {isFBLoggedIn ? (
            <Link to="/created-campaign" className="active-link">
              <button className="btn btn-primary">
                Created Campaigns
                <RiCoinsFill size={22} />
              </button>
            </Link>
          ) : (
            <div className="disabled-link">
              <button className="btn btn-primary disabled-button" disabled>
                Created Campaigns
                <RiCoinsFill size={22} />
              </button>
            </div>
          )}
        </div>

        {userData?.role === 'user' && (
          <>
            <AccountManagerDetails accountManager={accountManager} />
            <div className="mobile-only">
              <Link to="/ad-spendings-packages">
                <div className='ad-spend-top'>
                  <div className='adspend-text'>
                    <h4>Ad Spend Top-Up</h4>
                    <p>Manage your Marketing Funds</p>
                  </div>
                  <img src={adspendImage} alt="ad spend" />
                </div>
              </Link>
            </div>
            <CurrentPackageDisplay subscriptionData={subscriptionData} Link={Link} />
          </>
        )}

        <div className={`chat-btn-smm ${isFlex ? 'flex' : ''}`}  >
          {/* <div onClick={!isClicked ? handleClick : null}> */}
          <div>
            <Link to={'/chat'}>
              <button className="chat-btn" style={{ width: btnWidth }} disabled={isClicked}>
                <img src={chatImage} alt="chat" />
                {showText && (
                  <div className="text">
                    <p>Chat with</p>
                    <h6>Account Manager</h6>
                  </div>
                )}
              </button>
            </Link>
          </div>

          {showChat && (
            <div className={`chat-box ${showChat ? '' : 'hide'}`}>
              <div className="chat-header">
                <button className="close-btn" onClick={handleClose}><FaTimes /></button>
              </div>
              <div className="chat-body">
                {systemMessages && systemMessages.length > 0 ? (
                  <p>{systemMessages[0].message}</p>
                ) : (
                  <p>Hello & Welcome to SMM Experts, my name is Danish Kaleem & I am your Account Manager. How are doing today?</p>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="mobile-only">
          {userData?.role === 'user' && isHomePage && (
            <>
              <LinkedUsersPagesDropdownSearch />
            </>
          )}
        </div>
      </div>
    </Suspense>
  );
};

export default Sidebar;
