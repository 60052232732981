import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/components_css/PrivacyPolicy.css';
import Layout from '../GeneralComponents/Layout';
import { FaClipboardCheck } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

const TermsOfUse = () => {
    const settings = useSelector((state) => state.siteSettings.settings)
    return (
        <Layout
            icon={FaClipboardCheck}
            heading="Terms of Use"
            text="Effective Date: 01-01-2024"
        >
            <Container>
                <section className='privacy-policy-section' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.terms_use) }}
                >
                </section>
            </Container>
        </Layout>
    );
};

export default TermsOfUse;
