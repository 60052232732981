import React, { createContext, useContext } from 'react';

const RoleCheckContext = createContext();

export const useRoleCheck = () => useContext(RoleCheckContext);

export const RoleCheckProvider = ({ allowedRoles, children }) => (
    <RoleCheckContext.Provider value={{ allowedRoles }}>
        {children}
    </RoleCheckContext.Provider>
);
