import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/components_css/PrivacyPolicy.css';
import { FaFileAlt } from 'react-icons/fa';
import Layout from '../GeneralComponents/Layout';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

const TermAndConditions = () => {

    const settings = useSelector((state) => state.siteSettings.settings)
    return (
        <Layout
            icon={FaFileAlt}
            heading="Terms and Conditions"
            text="Effective Date: 01-01-2024"
        >
                <Container>
                    <section className='privacy-policy-section' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.terms_conditions) }}
                    >
                    </section>
                </Container>
        </Layout>
    );
};

export default TermAndConditions;
