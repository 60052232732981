import { createSlice } from '@reduxjs/toolkit';

const selectionSlice = createSlice({
  name: 'selection',
  initialState: {
    isSelectionMode: false,
    selectedMessages: [],
  },
  reducers: {
    toggleSelectionMode: (state) => {
      state.isSelectionMode = !state.isSelectionMode;
      state.selectedMessages = [];
    },
    selectMessage: (state, action) => {
      const messageId = action.payload;
      if (state.selectedMessages.includes(messageId)) {
        state.selectedMessages = state.selectedMessages.filter((id) => id !== messageId);
      } else {
        state.selectedMessages.push(messageId);
      }
    },
    clearSelectedMessages: (state) => {
      state.selectedMessages = [];
    },
  },
});

export const { toggleSelectionMode, selectMessage, clearSelectedMessages } = selectionSlice.actions;

export default selectionSlice.reducer;
