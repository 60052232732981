import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';

const SUB_TRIAL_URL = 'sub-trial/'


export const fetchSubTrialData = createAsyncThunk(
  'subscription/fetchSubTrialData',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`${SUB_TRIAL_URL}${userId}/`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Slice for handling subscription or trial data
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    data: null,
    isSubscriptionCreated: false,
    isSubscriptionCancelled: false,
    status: 'idle',
    error: null
  },
  reducers: {
    setSubscriptionCreated: (state, action) => {
      state.isSubscriptionCreated = action.payload;
    },
    setSubscriptionCancelled: (state, action) => {
      state.isSubscriptionCancelled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubTrialData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubTrialData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchSubTrialData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.data = null;
      });
  }
});


export const selectSubscriptionData = (state) => state.subscription.data
export const selectSubscriptionStatus = (state) => state.subscription.status
export const selectSubscriptionError = (state) => state.subscription.error
export const selectIsSubscriptionCreated = (state) => state.subscription.isSubscriptionCreated
export const selectIsSubscriptionCancelled = (state) => state.subscription.isSubscriptionCancelled
export const { setSubscriptionCreated, setSubscriptionCancelled } = subscriptionSlice.actions;


export default subscriptionSlice.reducer;