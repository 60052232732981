import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/components_css/PrivacyPolicy.css';
import Layout from '../GeneralComponents/Layout';
import { MdPrivacyTip } from 'react-icons/md';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

const PrivacyPolicy = () => {
    const settings = useSelector((state) => state.siteSettings.settings)

    return (
        <Layout
            icon={MdPrivacyTip}
            heading="Privacy Policy"
            text="Effective Date: 01-01-2024"
        >
           
                <Container>
                    <section className="privacy-policy-section firt-sect " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.privacy_policy) }}>

                    </section>

                </Container>
        </Layout>
    );
};

export default PrivacyPolicy;
