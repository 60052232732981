// src/components/Callback.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import '../../../assets/components_css/social.css';

const Callback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFacebookData = async (code) => {
            try {
                const response = await axios.post('login/callback/', { code });

                if (response.data['API Response']) {
                    navigate('/');
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/');
            }
        };

        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');

        if (code) {
            fetchFacebookData(code);
        } else {
            navigate('/');
        }
    }, [location, navigate]);

    return (
        <div className='container-socials'>
            Connecting you Meta account, please wait
        </div>
    );
};

export default Callback;
