import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import axios from '../../../api/axios';
import { useNavigate, Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import Layout from '../../GeneralComponents/Layout';
import { selectUserData } from '../../../redux/userSlice';
import '../../../assets/components_css/social.css';
import Loading from '../../UserManagement/Loading';
import { Suspense } from 'react';
import { logout } from "../../../redux/facebookSlice";

const INVITE_MARKETER = 'invite-marketer/';

const MetaDashboard = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.facebookLiveData.userInfo);
    const businessAccounts = useSelector((state) => state.facebookLiveData.businessAccounts);
    const giveAccess = useSelector((state) => state.facebook.giveAccess);
    const [selectedPages, setSelectedPages] = useState({});
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [loadingAccess, setLoadingAccess] = useState({});
    const [givenAccess, setGivenAccess] = useState({});
    const [isAccessDisabled, setIsAccessDisabled] = useState(false);
    const userData = useSelector(selectUserData);
    const isUser = userData?.role === 'user';
    const dispatch = useDispatch();

    // Load given access state from localStorage on mount
    useEffect(() => {
        const savedGivenAccess = JSON.parse(localStorage.getItem('givenAccess')) || {};
        setGivenAccess(savedGivenAccess);
        
        const savedDisabledState = JSON.parse(localStorage.getItem(`disabledState_${userInfo.id}`));
        if (savedDisabledState) {
            setIsAccessDisabled(true);
        }
    }, [userInfo.id]);

    // Save given access state to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('givenAccess', JSON.stringify(givenAccess));
    }, [givenAccess]);

    const handlePageSelection = (accountId, pageId) => {
        setSelectedPages((prevState) => {
            const newState = { ...prevState };
            if (!newState[accountId]) {
                newState[accountId] = {};
            }
            newState[accountId][pageId] = !newState[accountId][pageId];
            return newState;
        });
    };

    const isAnyPageSelected = (accountId) => {
        return selectedPages[accountId] && Object.values(selectedPages[accountId]).some((selected) => selected);
    };

    const handleGiveAccess = useCallback(async (accountId) => {
        setLoadingAccess((prevState) => ({ ...prevState, [accountId]: true }));
        setIsAccessDisabled(true);
        
        const accountPages = selectedPages[accountId] || {};
        const pageIds = Object.keys(accountPages).filter(pageId => accountPages[pageId]);

        // Save the disabled state to localStorage
        localStorage.setItem(`disabledState_${userInfo.id}`, true);

        try {
            const response = await axios.post(INVITE_MARKETER, {
                business_id: accountId,
                page_ids: pageIds,
            });

            if (response.status === 200) {
                toast.success('Invitation sent successfully!');
                setGivenAccess((prevState) => {
                    const newState = { ...prevState };
                    newState[accountId] = pageIds;
                    return newState;
                });
            } else {
                toast.error('Failed to send invitation.');
            }
        } catch (error) {
            toast.error(error.response.data.error);
            console.error('Error sending invitation:', error);
        } finally {
            setLoadingAccess((prevState) => ({ ...prevState, [accountId]: false }));
        }
    }, [selectedPages, userInfo.id]);

    const handleLogout = useCallback(() => {
        setIsDisconnecting(true);
        dispatch(logout());
        setTimeout(() => {
            navigate('/');
        }, 2000);
    }, [dispatch, navigate]);

    return (
        <Layout
            icon={FaUser}
            heading="User Dashboard"
            isUser={isUser}
            Show={true}
            Link={Link}
            Text="Connected Meta Assests"
            URL={'/connected-meta-assets'}
            className='Meta-haeder'
        >

            <Suspense fallback={<Loading />}>
                <section>
                    {userInfo && (
                        <>
                            <h1 className='connect-profiles'> Meta Profile </h1>
                            <div className='account-header'>
                                <div className='connected-profiles'>
                                    <img src={userInfo.picture_url} alt="fb-main-profile" />
                                    <div className='user-infor'>
                                        <h6>{userInfo.name}</h6>
                                        <p>@{userInfo.id}</p>
                                    </div>
                                </div>
                                <button
                                    className="give-access-button disconnect-button"
                                    onClick={handleLogout}
                                    disabled={isDisconnecting}
                                >
                                    {isDisconnecting ? (
                                        <span className="spinner" />
                                    ) : (
                                        'Logout'
                                    )}
                                </button>
                            </div>
                        </>
                    )}

                    <div className="business-accounts">
                        <h1 className='connect-profiles'>Meta Assests <span className='int-BP main'>(Businesses & Pages)</span></h1>
                        {businessAccounts && businessAccounts.map((account) => (
                            <div key={account.id} className="account">
                                <div className="account-header">
                                    <div className='accunt-img-inf'>
                                        <img src={account.image_url} alt={account.name} className="account-image" />
                                        <div className="account-info">
                                            <h3>{account.name} <span className='int-BP'>(Business)</span></h3>
                                            <p>@{account.id}</p>
                                        </div>
                                    </div>
                                    <button
                                        className="give-access-button"
                                        onClick={() => handleGiveAccess(account.id)}
                                        disabled={!isAnyPageSelected(account.id) || loadingAccess[account.id] || isAccessDisabled}
                                    >
                                        {loadingAccess[account.id] ? (
                                            <span className="spinner" />
                                        ) : (
                                            'Give Access'
                                        )}
                                    </button>
                                </div>
                                {account.pages.map((page) => (
                                    <div key={page.id} className={`page ${givenAccess[account.id] && givenAccess[account.id].includes(page.id) ? 'disabled' : ''}`}>
                                        <input
                                            type="checkbox"
                                            id={page.id}
                                            name={page.name}
                                            className="custom-checkbox"
                                            onChange={() => handlePageSelection(account.id, page.id)}
                                            checked={selectedPages[account.id] && selectedPages[account.id][page.id]}
                                            disabled={givenAccess[account.id] && givenAccess[account.id].includes(page.id) || isAccessDisabled}
                                        />
                                        <label htmlFor={page.id} className={`custom-checkbox-label ${givenAccess[account.id] && givenAccess[account.id].includes(page.id) ? 'disabled' : ''} ${isAccessDisabled ? 'disabled' : ''}`}></label>
                                        <label htmlFor={page.id} className={`met-dasi-brd ${isAccessDisabled ? 'disabled' : ''}`}>
                                            <img src={page.image_url} alt={page.name} className="page-image" />
                                            <div className="page-info">
                                                <h4>{page.name} <span className='int-BP'>(Page)</span></h4>
                                                <p>@{page.id}</p>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </section>
            </Suspense>
        </Layout>
    );
};

export default MetaDashboard;
