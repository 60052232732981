import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';
const USER_DATA = 'user-data/';
const USER_ACCOUNT_UPDATE = 'user-account-update/';
const LOCAL_STORAGE_KEY = 'accessToken';
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';
const getStoredAccessToken = () => localStorage.getItem(LOCAL_STORAGE_KEY);
const getStoredRefreshToken = () => localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
const saveAccessTokenToLocalStorage = (token) => localStorage.setItem(LOCAL_STORAGE_KEY, token);
const saveRefreshTokenToLocalStorage = (token) => localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, token);
const removeAccessTokenFromLocalStorage = () => localStorage.removeItem(LOCAL_STORAGE_KEY);
const removeRefreshTokenFromLocalStorage = () => localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (_, { getState, rejectWithValue }) => {
        try {
            const response = await axios.get(USER_DATA);
            return response.data;
        } catch (error) {
            console.error('Fetch user data failed:', error);
            return rejectWithValue('Fetch user data failed');
        }
    }
);
export const updateAccountSettings = createAsyncThunk(
    'user/updateAccountSettings',
    async (userData, { getState, rejectWithValue }) => {
        try {
            const response = await axios.patch(USER_ACCOUNT_UPDATE, userData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Update account settings failed:', error);
            return rejectWithValue('Update account settings failed');
        }
    }
);
const initialState = {
    accessToken: getStoredAccessToken(),
    refreshToken: getStoredRefreshToken(),
    isAuthenticated: !!getStoredAccessToken(),
    onlineStatus: {},
    isConnected: true,
    userData: null,
    isLoading: false,
    error: null,
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAccessToken(state, action) {
            state.accessToken = action.payload;
            state.isAuthenticated = true;
            saveAccessTokenToLocalStorage(action.payload);
        },
        clearAccessToken(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            removeAccessTokenFromLocalStorage();
            removeRefreshTokenFromLocalStorage();
        },
        setRefreshToken(state, action) {
            state.refreshToken = action.payload;
            saveRefreshTokenToLocalStorage(action.payload);
        },
        updateUserData(state, action) {
            state.userData = action.payload;
        },
        updateUserStatus: (state, action) => {
            const { userId, online } = action.payload;
            state.onlineStatus[userId] = online;
        },
        updateConnectionStatus: (state, action) => { // Add this reducer
            state.isConnected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userData = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(updateAccountSettings.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateAccountSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userData = action.payload;
            })
            .addCase(updateAccountSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});
export const { setAccessToken, clearAccessToken, updateUserData, setRefreshToken, updateUserStatus, updateConnectionStatus } = userSlice.actions
export const selectAccessToken = (state) => state.user.accessToken
export const selectIsAuthenticated = (state) => state.user.isAuthenticated
export const selectUserData = (state) => state.user.userData
export const selectOnlineStatus = (state) => state.user.onlineStatus
export const selectConnectionStatus = state => state.user.isConnected
export const selectUserLoading = (state) => state.user.isLoading
export const selectUserError = (state) => state.user.error


export default userSlice.reducer