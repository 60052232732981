// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import BASE_URL from '../config';
// import axios from 'axios';

// export const fetchCalendarData = createAsyncThunk(
//     'calendar/fetchCalendarData',
//     async (userId) => {
//         try {
//             const token = localStorage.getItem('token')
//             const response = await axios.get(`${BASE_URL}/users/${userId}/calendar-post-content/`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             })
//             return response.data
//         } catch (error) {
//             throw error
//         }
//     }
// )

// const initialState = {
//     events: [],
//     loading: false,
//     erorr: null
// }

// const calendarSlice = createSlice({
//     name: 'calendar',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchCalendarData.pending, (state) => {
//                 state.loading = true
//                 state.erorr = null
//             })
//             .addCase(fetchCalendarData.fulfilled, (state, action) => {
//                 state.loading = false
//                 state.events = action.payload
//             })
//             .addCase(fetchCalendarData.rejected, (state, action) => {
//                 state.loading = false
//                 state.erorr = action.error.message
//             })
//     }
// })

// export default calendarSlice.reducer


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../api/axios'; // Assuming this is the centralized Axios instance

const CALENDAR_POST_CONTENT_URL = 'get-page-posts/';

export const fetchCalendarData = createAsyncThunk(
    'calendar/fetchCalendarData',
    async ({ pageId, userId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${CALENDAR_POST_CONTENT_URL}${userId}/${pageId}/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    events: [],
    status: 'idle', // Use 'idle', 'loading', 'succeeded', 'failed' for consistency
    error: null
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        clearCalendarEvents: (state) => {
            state.events = [];
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalendarData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCalendarData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = action.payload;
            })
            .addCase(fetchCalendarData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch calendar data';
            });
    }
});

export const { clearCalendarEvents } = calendarSlice.actions;

export default calendarSlice.reducer;
