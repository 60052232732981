import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';

export const fetchUserDetails = createAsyncThunk(
    'linkedUser/fetchUserDetails',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/linked-users/${userId}/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const linkedUserDetailsSlice = createSlice({
    name: 'linkedUserDetails',
    initialState: {
        linkedUserDetails: null,
        status: 'idle',
        error: null
    },
    reducers: {
        clearUserDetails: (state) => {
            state.linkedUserDetails = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.linkedUserDetails = action.payload;
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { clearUserDetails } = linkedUserDetailsSlice.actions;

export const selectLinkedUserDetails = (state) => state.linkedUserDetails.linkedUserDetails;
export const selectLinkedUserDetailsStatus = (state) => state.linkedUserDetails.status;
export const selectLinkedUserDetailsError = (state) => state.linkedUserDetails.error;

export default linkedUserDetailsSlice.reducer;
