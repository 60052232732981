import { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import Login from './components/UserManagement/Login'
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated, selectUserData } from "./redux/userSlice";
import { ToastContainer } from 'react-toastify';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import RequireAuth from './RequireAuth'
import { RoleCheckProvider } from './RoleCheckProvider';
import FacebookCallback from './components/Socials/Facebook/FacebookCallback';
import CodeCheckRoute from './CodeCheckRoute';
import FBRoute from './FBRoute';
import MetaDashboard from './components/Socials/Facebook/MetaDashboard';
import ConnectedMetaAssets from './components/Socials/Facebook/ConnectedMetaAssets';
// import AMConnectedMetaAssets from './components/AccountManager/AMConnectedMetaAssets';
import Loading from './components/UserManagement/Loading';
import Sidebar from './components/GeneralComponents/SideBar';
import AdminSidebar from './components/AdminComponents/AdminSidebar';
import PrivacyPolicy from './components/TermsAndSCP/PrivacyPolicy';
import TermAndConditions from './components/TermsAndSCP/TermsAndConditions';
import TermsOfUse from './components/TermsAndSCP/TermsOfUse';
import TermsOfService from './components/TermsAndSCP/TermsOfService';
import HelpCenter from './components/TermsAndSCP/HelpCenter';
import { subscribeUserToPush } from './utils/pushSubscription';
import { getSiteSettings } from './redux/siteSettingsSlice';

// Dynamic imports with React.lazy for all route components
const CreateAdsSpending = lazy(() => import("./components/AccountManager/CreateAdsSpending"));
const AdsSpendingAM = lazy(() => import("./components/AccountManager/AdsSpendingAM"));
const AdsSpendings = lazy(() => import("./components/AdsSpendings"));
const CurrentPackage = lazy(() => import("./components/CurrentPackage"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Register = lazy(() => import("./components/UserManagement/Register"));
const VerifyEmail = lazy(() => import("./components/UserManagement/VerifyEmail"));
const Header = lazy(() => import("./components/HeaderComponents/Header"));
const HeaderLoggedOut = lazy(() => import("./components/HeaderComponents/HeaderLoggedOut "));
const Footer = lazy(() => import("./components/Footer/Footer"))
const AccountSettings = lazy(() => import("./components/AccountSettings"));
const MarketingManagers = lazy(() => import("./components/AdminComponents/MarketingManagers"));
const AccountManagers = lazy(() => import("./components/AdminComponents/AccountManagers"));
const AssignUsers = lazy(() => import("./components/AdminComponents/AssignUsers"));
const Assignments = lazy(() => import("./components/AdminComponents/Assignments"));
const CreatePackage = lazy(() => import("./components/AdminComponents/CreatePackage"));
const PricingPlans = lazy(() => import("./components/AdminComponents/PricingPlans"));
const Subscriptions = lazy(() => import("./components/AdminComponents/Subscriptions"));
const CreateCustomPackage = lazy(() => import("./components/AccountManager/CreateCustomPackage"));
const PricingPlansAM = lazy(() => import("./components/AccountManager/PricingPlansAM"));
const Packages = lazy(() => import("./components/Packages"));
const Cart = lazy(() => import("./components/Cart"));
const Checkout = lazy(() => import("./components/Checkout"));
const CreateCouponForm = lazy(() => import("./components/AdminComponents/CreateCouponForm"));
const CouponsTable = lazy(() => import("./components/AdminComponents/CouponsTable"));
const PaymentSetting = lazy(() => import("./components/PaymentSetting"));
const CustomCoupons = lazy(() => import('./components/AccountManager/CustomCoupons'));
const CreateCustomCoupons = lazy(() => import('./components/AccountManager/CreateCustomCoupons'));
const NotFound = lazy(() => import("./components/UserManagement/NotFound"));
const UnauthorizedAccess = lazy(() => import("./components/UserManagement/UnauthorizedAccess"));
const ForgotPassword = lazy(() => import("./components/UserManagement/ForgotPassword"));
const ForgotPasswordResendLink = lazy(() => import("./components/UserManagement/ForgotPasswordResendLink"));
const CreatePassword = lazy(() => import("./components/UserManagement/CreatePassword"));
const PublishPost = lazy(() => import("./components/MarketingManager/Publishpost"));
const MarketingManagerList = lazy(() => import('./components/AccountManager/MarketingManagerList'));
const UserListMM = lazy(() => import('./components/AccountManager/UserListMM'));
const Chat = lazy(() => import('./components/Chat/Chat'));
const CreateCampaign = lazy(() => import('./components/MarketingManager/CreateCampaign'));
const CreatedCampaign = lazy(() => import('./components/GeneralComponents/CreatedAdCampaign'));
const SiteSettings = lazy(() => import('./components/AdminComponents/SiteSettingsForm'));

// Stripe public key
const stripePromise = loadStripe('pk_test_51OXoGuIbeKY9CLeZAz6eQ4XjGJfib7LkMwLjkk65JAlbZndDeS7MtuvVsZACfZlKV8PmxzJOx8Su8qj2hOLCDqOI009MXi2Ycu');


export default function App() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUserData)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAdminSidebarOpen, setIsAdminSidebarOpen] = useState(false);
  // const isAuthenticated = true;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleAdminSidebar = () => {
    setIsAdminSidebarOpen(!isAdminSidebarOpen);
  };

  // Define a custom route for the login and register pages
  const AuthRoute = ({ element, ...props }) => {
    return isAuthenticated ? <Navigate to="/" replace /> : element;
  };

  useEffect(() => {
    if (user) {
      subscribeUserToPush(user.id).then(subscription => {
        if (subscription) {
          console.log('Subscribed to push notifications:', subscription);
        }
      });
    }
  }, [user]);

  const dispatch = useDispatch()
  const { settings, loading, error } = useSelector((state => state.siteSettings))
  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    dispatch(getSiteSettings());
  }, [dispatch]);
  useEffect(() => {
    if (settings?.stripe_public_key) {
      const stripe = loadStripe(settings.stripe_public_key);
      setStripePromise(stripe);
    }
  }, [settings?.stripe_public_key]);

  return (
    <>
      <style>
        {`
                    :root {
                        --primary-color: ${settings?.brand_color_primary || '#1b1b1b'};
                        --secondary-color: ${settings?.brand_color_secondary || '#1b1b1b'};
                        --text-color:${settings?.text_color || '#1b1b1b'};
                        --background-color: ${settings?.button_bg_color || '#ebf6f5'}
                    }
                `}
      </style>
      <Router>
        <Elements stripe={stripePromise}>
          <Suspense fallback={<div>loading...</div>}>
            {isAuthenticated ? (
              <>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} isAdminSidebarOpen={isAdminSidebarOpen} toggleAdminSidebar={toggleAdminSidebar} />
                <div className="app-sidebar">
                  <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                </div>
                <div className="app-admin-sidebr">
                  <AdminSidebar isAdminSidebarOpen={isAdminSidebarOpen} toggleAdminSidebar={toggleAdminSidebar} />
                </div>
              </>

            ) : (
              <HeaderLoggedOut />
            )}
            <Routes >
              {/* Use AuthRoute for login and register routes */}
              <Route path="/register" element={<AuthRoute element={<Register />} />} />
              <Route path="/login" element={<AuthRoute element={<Login />} />} />
              <Route path="/verify" element={<VerifyEmail />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermAndConditions />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/help-center" element={<HelpCenter />} />
              {/* New route for forget password */}
              <Route path="/forgot-password-resend-link" element={<ForgotPassword />} />
              <Route path="/forgot-password" element={<ForgotPasswordResendLink />} />
              <Route path="/reset-password" element={<CreatePassword />} />
              {/* Protected routes for authenticated users */}
              <Route element={<RoleCheckProvider allowedRoles={['admin']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/marketing-managers" element={<MarketingManagers />} />
                <Route path="/account-managers" element={<AccountManagers />} />
                <Route path="/assign-users" element={<AssignUsers />} />
                <Route path="/assignments" element={<Assignments />} />
                <Route path="/create-package" element={<CreatePackage />} />
                <Route path="/pricing-plans" element={<PricingPlans />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/create-coupon" element={<CreateCouponForm />} />
                <Route path="/coupons" element={<CouponsTable />} />
                <Route path="/site-settings" element={<SiteSettings />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['account_manager']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/create-custom-package" element={<CreateCustomPackage />} />
                <Route path="/custom-packages" element={<PricingPlansAM />} />
                <Route path="/ad-spendings" element={<AdsSpendingAM />} />
                <Route path="/create-ads-spending" element={<CreateAdsSpending />} />
                <Route path="/custom-coupons" element={<CustomCoupons />} />
                <Route path="/create-custom-coupon" element={<CreateCustomCoupons />} />
                <Route path="/linked-managers" element={<MarketingManagerList />} />
                <Route path="/linked-users" element={<UserListMM />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['marketing_manager']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/publish-post/:pageId" element={<PublishPost />} />
                <Route path="/linked-users" element={<UserListMM />} />
                <Route path="/create-campaign" element={<CreateCampaign />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['user']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/login/callback" element={<CodeCheckRoute><FacebookCallback /></CodeCheckRoute>} />
                <Route path="/meta-dashboard" element={<FBRoute><MetaDashboard /></FBRoute>} />
                <Route path="/connected-meta-assets" element={<FBRoute requireAccess={true}><ConnectedMetaAssets /></FBRoute>} />
                <Route path="/current-package" element={<CurrentPackage />} />
                <Route path="/ad-spendings-packages" element={<AdsSpendings />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/payment-settings" element={<PaymentSetting />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['user', 'marketing_manager', 'account_manager', 'admin']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/account-settings" element={<AccountSettings />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['user', 'marketing_manager']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/created-campaign" element={<CreatedCampaign />} />
              </Route>
              <Route element={<RoleCheckProvider allowedRoles={['user', 'account_manager']}><RequireAuth /></RoleCheckProvider>}>
                <Route path="/chat" element={<Chat />} />
              </Route>
              <Route path="*" element={<NotFound />} />
              <Route path="/not-authorized" element={<UnauthorizedAccess />} />
            </Routes >
            {isAuthenticated && <Footer />}
          </Suspense>
        </Elements>
      </Router>
      <ToastContainer />
    </>
  )
}
