import React, { useRef, useEffect, useCallback, useState } from 'react';
import BASE_URL from "../../config";
import UserDropdownMenu from "./UserDropdownMenu";
import personAvatar from '../../assets/Images/personAvatar.png';

const UserInteractionSection = ({ userData, isDropdownOpen, toggleDropdown, handleLogout, Link }) => {
    const dropdownRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            toggleDropdown(false);
        }
    }, [toggleDropdown]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div className="col-2-5 usr-bx-cntner" ref={dropdownRef}>
            <div className="usr-bx-gnrl usr-drpdwn-opener" onClick={() => toggleDropdown(!isDropdownOpen)}>
                <div className="usr-img">
                    <img src={userData.profile_picture ? `${BASE_URL}/${userData.profile_picture}` : (personAvatar)} alt="User Avatar" />
                </div>
                        <div className="usr-nme">
                            <h6>Hi, {userData.first_name} {userData.last_name}</h6>
                        </div>
                        <div></div>
                        {/* <div></div> */}
                        <div className="usr-icons">
                            <div className="acc-ico drpdwn-ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                    <path d="M14.9743 0.734344C14.9233 0.613653 14.8052 0.535156 14.674 0.535156L0.326119 0.535315C0.195078 0.535315 0.0767757 0.613812 0.0256652 0.734503C-0.025286 0.855194 0.000985725 0.994832 0.0923796 1.08877L7.26632 8.46731C7.32762 8.53037 7.41201 8.56603 7.50006 8.56603C7.58811 8.56603 7.6725 8.53037 7.7338 8.46731L14.9077 1.08861C14.9991 0.994514 15.0252 0.855035 14.9743 0.734344Z" fill="var(--primary-color)" />
                                </svg>
                            </div>
                        </div>
            </div>
            {isDropdownOpen && (
                <UserDropdownMenu userData={userData} handleLogout={handleLogout} Link={Link} />
            )}
        </div>
    );
};

export default UserInteractionSection;
