import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectUserData, selectUserLoading } from './redux/userSlice';
import { useRoleCheck } from './RoleCheckProvider'; // Assuming it's in the same directory

const RequireAuth = () => {
  const location = useLocation();
  const { allowedRoles } = useRoleCheck();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userData = useSelector(selectUserData);
  const isLoadingUserData = useSelector(selectUserLoading);

  if (isLoadingUserData) {
    return <div></div>; // Or some loading spinner
  }


  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (userData && allowedRoles && !allowedRoles.includes(userData?.role)) {
    return <Navigate to="/not-authorized" replace />;
  }


  return <Outlet />;
};

export default RequireAuth;
