import React from 'react';
import { formatSubscriptionEndDate } from '../../utils/utils';
import packImage from '../../assets/Images/pack.svg';


const CurrentPackageDisplay = ({ subscriptionData, Link }) => {
    console.log(subscriptionData);
    if (!subscriptionData) {
        return null;
    }

    return (
        <div className="col">
            <div className="schdle-mting-wrapper pkg-wrapper">
                <Link to={'/current-package'} className='schdle-mting-btn'>
                    <div className="schdle-mting-btn-txt slt-pct">
                        <p className='slcted-lbl'>Selected Package</p>
                        <h5 className='pack-head'>{subscriptionData.name}</h5>
                        <p className='exp-date'>Expiring on {formatSubscriptionEndDate(subscriptionData.end_date)}</p>
                    </div>

                    <div className="schdle-mting-icon">
                        {/* <img src={packImage} alt="package" /> */}
                        <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.935 0.328125H10.576C8.985 0.328125 7.69141 1.62236 7.69141 3.21274C7.69141 4.80377 8.985 6.09736 10.576 6.09736H29.935C31.5254 6.09736 32.8196 4.80377 32.8196 3.21274C32.8196 1.62236 31.5254 0.328125 29.935 0.328125Z" fill="var(--secondary-color)" />
                            <path d="M29.935 9.94531H10.576C8.985 9.94531 7.69141 11.2395 7.69141 12.8299C7.69141 14.421 8.985 15.7145 10.576 15.7145H29.935C31.5254 15.7145 32.8196 14.421 32.8196 12.8299C32.8196 11.2395 31.5254 9.94531 29.935 9.94531Z" fill="var(--secondary-color)" />
                            <path d="M29.935 19.5586H10.576C8.985 19.5586 7.69141 20.8528 7.69141 22.4432C7.69141 24.0342 8.985 25.3278 10.576 25.3278H29.935C31.5254 25.3278 32.8196 24.0342 32.8196 22.4432C32.8196 20.8528 31.5254 19.5586 29.935 19.5586Z" fill="var(--secondary-color)" />
                            <path d="M2.88462 0.328125C1.29359 0.328125 0 1.62236 0 3.21274C0 4.80377 1.29359 6.09736 2.88462 6.09736C4.475 6.09736 5.76923 4.80377 5.76923 3.21274C5.76923 1.62236 4.475 0.328125 2.88462 0.328125Z" fill="var(--secondary-color)" />
                            <path d="M2.88462 9.94531C1.29359 9.94531 0 11.2395 0 12.8299C0 14.421 1.29359 15.7145 2.88462 15.7145C4.475 15.7145 5.76923 14.421 5.76923 12.8299C5.76923 11.2395 4.475 9.94531 2.88462 9.94531Z" fill="var(--secondary-color)" />
                            <path d="M2.88462 19.5586C1.29359 19.5586 0 20.8528 0 22.4432C0 24.0342 1.29359 25.3278 2.88462 25.3278C4.475 25.3278 5.76923 24.0342 5.76923 22.4432C5.76923 20.8528 4.475 19.5586 2.88462 19.5586Z" fill="var(--secondary-color)" />
                        </svg>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default CurrentPackageDisplay;
