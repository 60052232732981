import { configureStore } from '@reduxjs/toolkit'
// import { thunk } from 'redux-thunk'
import calendarReducer from './calendarSlice'
import userReducer from './userSlice'
import searchSlice from './searchSlice'
import userAccountManagerSlice from './userAccountManagerSlice'
import cartReducer from './cartSlice'
import subscriptionSlice from './subscriptionSlice'
import facebookReducer from './facebookSlice'
import facebookLiveDataReducer from './facebookLiveDataSlice'
// import chatSlice from './chatSlice'
// import websocketMiddleware from '../middleware/websocketMiddleware'
import notificationSlice from './notificationSlice'
import linkedUserDetailsSlice from './linkedUserSlice';
import selectionReducer from './selectionSlice';
import siteSettingsSlice from './siteSettingsSlice';

const store = configureStore({
    reducer: {
        calendar: calendarReducer,
        user: userReducer,
        search: searchSlice,
        userAccountManager: userAccountManagerSlice,
        cart: cartReducer,
        subscription: subscriptionSlice,
        facebook: facebookReducer,
        facebookLiveData: facebookLiveDataReducer,
        // chat: chatSlice,
        notification: notificationSlice,
        linkedUserDetails: linkedUserDetailsSlice,
        selection: selectionReducer,
        siteSettings: siteSettingsSlice,

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(websocketMiddleware)
})

export default store