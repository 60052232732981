import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Container, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken } from '../../redux/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/components_css/UserManagement.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

const LOGIN_URL = 'login/';
const MARK_INACTIVE = 'user/mark-inactive/';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = useMemo(() => ({
        email: '',
        password: '',
    }), []);

    const validationSchema = useMemo(() => Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    }), []);

    const handleSubmit = useCallback(async (values, { setSubmitting }) => {
        try {
            const { data } = await axios.post(LOGIN_URL, values);
            dispatch(setAccessToken(data?.access));
            dispatch(setRefreshToken(data?.refresh));
           
            await axios.post(MARK_INACTIVE);
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error);
            toast.error('Login failed. Please check your credentials.');
        } finally {
            setSubmitting(false);
        }
    }, [dispatch, navigate]);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };


    return (
        <Container>
            <Row className='justify-content-center mt-5 login-pge'>
                <Col xs={12} md={8} lg={6}>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="login-pg">
                                    <div className='frm-col'>
                                        <h1 >Login</h1>
                                        <div className='section-divider'>
                                            <div className="divider"></div>
                                            <div className="or-text">or</div>
                                            <div className="divider"></div>
                                        </div>
                                        <div >
                                            <label htmlFor="email" className='form-label'>Email</label>
                                            <Field type="text" name="email" id="email" className="form-control" placeholder="Email Address" />
                                            <ErrorMessage name='email' component="div" className='text-danger' />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className='form-label'>Password</label>
                                            <div className="password-field">
                                                <Field type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Password" />
                                                <button type="button" className="password-toggle-btn login-btn-pass" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                            </div>
                                            <ErrorMessage name='password' component="div" className='text-danger' />
                                        </div>
                                        <h5 className='forget-lnk'> <Link to="/forgot-password">Forgot Password?</Link></h5>
                                    </div>
                                </div>
                                <Button type='submit' variant='primary' disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <>
                                            <span className="spinner"></span> Logging in....
                                        </>
                                    ) : (
                                        'Login'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>

                    <p>Don't have an account? <Link to="/register">Register</Link></p>
                </Col>
            </Row>
        </Container>
    );
};

export default React.memo(Login);